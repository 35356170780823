import type { IComponentBackground, IComponentFont } from '~ui/types/ComponentStyles';
import type { IFont, IFontSize, ITextStyle } from '~ui/types/Font';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '~ui/tailwind.config';
import type { IBackground, IBackgroundColor, IBackgroundImage } from '~ui/types/Background';
import { EBackgroundImageFit } from '~ui/types/enums/ComponentBackground';
import defaultColors from 'tailwindcss/colors';
import { EFontColor } from '~ui/types/enums/ComponentStyle';
import { hexToRgba } from '~ui/utils/hexToRGBA';

const fullConfig = resolveConfig(tailwindConfig);

export function buildFonts(font: IComponentFont | undefined): IFont {
    const appConfig = useAppConfig();
    let color: string | null = null;
    if (font?.fontColor) {
        if (font.fontColor === EFontColor.CUSTOM) {
            color = font.customFontColor.color || null;
        } else {
            const colorConfig =
                appConfig.tailwindCss.config.theme.extend.colors[font.fontColor] || defaultColors[font.fontColor];
            color =
                typeof colorConfig === 'string'
                    ? colorConfig
                    : colorConfig?.DEFAULT || colorConfig?.['500'] || Object.values(colorConfig || {})?.[0] || null;
        }
    }

    return {
        '--font-color': color,
        color: color ? 'var(--font-color)' : null,
        'font-weight': fullConfig.theme.fontWeight[font?.fontWeight] || null,
        'font-style': font?.fontStyle || null,
        'text-align': font?.alignment || null,
    };
}

export function buildFontSize(font: IComponentFont | undefined): IFontSize {
    return {
        'font-size': font?.fontSize ? `${font?.fontSize}%` : null,
    };
}

export function buildTextStyles(font: IComponentFont | undefined): ITextStyle {
    return {
        ...buildFontSize(font),
        'text-decoration': font?.textDecoration || null,
    };
}

export function buildBackground(
    background: IComponentBackground | undefined
): IBackground | IBackgroundImage | IBackgroundColor | undefined {
    if (background?.hasGradient) {
        return {
            background: `linear-gradient(180deg, ${background.startGradient?.color} 0%, ${background.endGradient?.color} 100%)`,
        };
    }

    if (background?.hasBackgroundImage) {
        const backgroundSize = !background.backgroundImageFit
            ? null
            : background.backgroundImageFit !== EBackgroundImageFit.CONTAIN_NO_REPEAT
              ? background.backgroundImageFit
              : EBackgroundImageFit.CONTAIN;

        return {
            'background-image': `url(${background.backgroundImage?.filename})`,
            'background-size': backgroundSize,
            'background-repeat':
                background.backgroundImageFit === EBackgroundImageFit.CONTAIN_NO_REPEAT ? 'no-repeat' : null,
            'background-position': background.backgroundImagePosition || null,
        };
    }

    if (background?.color?.color) {
        // calc bg color opacity (1 is default)
        const bgOpacity = background?.colorOpacity ? background?.colorOpacity / 100 : 1;
        const rgbaBgColor = hexToRgba(background.color.color, bgOpacity);
        return {
            '--background-color': rgbaBgColor || null,
            'background-color': rgbaBgColor ? 'var(--background-color)' : null,
        };
    }
}
