export enum EBackgroundImagePosition {
    CENTER = 'center',
    RIGHT = 'right',
    LEFT = 'left',
}

export enum EBackgroundImageFit {
    COVER = 'cover',
    CONTAIN = 'contain',
    CONTAIN_NO_REPEAT = 'contain no-repeat',
}
